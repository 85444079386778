import * as $ from "jquery";

document.addEventListener('DOMContentLoaded', (e) => {
    let queryParams = new URLSearchParams(window.location.search);
    const $itemsPerPage = $("#js-proofing-scorecard-items-per-page");

    if ($itemsPerPage) {
        $itemsPerPage.on("change", function(e) {
            queryParams.set("per_page", $itemsPerPage.val());
            queryParams.sort() // sort query params to match with Rails
            window.location.replace(`${window.location.pathname}?${queryParams.toString()}`);
        });
    }
});